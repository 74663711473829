body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.container {
    margin-bottom: 20px; /* Space between text and logo */
    margin-top: -50px; /* Move the text 50px higher */
}

.splash-container {
    display: flex;
    justify-content: center; /* Center the logo horizontally */
    width: 100%;
}

.logo {
    width: 50%;
    max-width: 300px; /* Adjust max-width as needed */
    height: auto;
    display: block;
}

#neon-text {
    font-size: 3rem;
    font-family: 'Arial', sans-serif;
    color: #00ff00; /* Initial neon color */
    text-shadow: 0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00, 0 0 20px #00ff00, 0 0 25px #00ff00, 0 0 30px #00ff00, 0 0 35px #00ff00;
    transition: color 1s ease-in-out, text-shadow 1s ease-in-out;
}