body, html {
  color: #fff;
  text-align: center;
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.container {
  margin-top: -50px;
  margin-bottom: 20px;
}

.splash-container {
  justify-content: center;
  width: 100%;
  display: flex;
}

.logo {
  width: 50%;
  max-width: 300px;
  height: auto;
  display: block;
}

#neon-text {
  color: #0f0;
  text-shadow: 0 0 5px #0f0, 0 0 10px #0f0, 0 0 15px #0f0, 0 0 20px #0f0, 0 0 25px #0f0, 0 0 30px #0f0, 0 0 35px #0f0;
  font-family: Arial, sans-serif;
  font-size: 3rem;
  transition: color 1s ease-in-out, text-shadow 1s ease-in-out;
}
/*# sourceMappingURL=index.4a6ca6a4.css.map */
